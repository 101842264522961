/* eslint-disable react-hooks/rules-of-hooks */
/**
 * TODO: Refactor component to follow React Hooks rules properly.
 */

import React, { useRef, useState, useLayoutEffect, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { logger } from 'src/utils/logger';
// Components
import Icon from 'HTKit/Icon';
import HeadlineLnk from 'src/components/PromoBar/HeadlineLink';
import PromoGuard from 'src/components/PromoBar/Guard';
import { useSplitIOTreatment, splitioConstants } from 'src/components/SplitIO';
// Styles
import styles from 'src/components/PromoBar/style.scss';
// Actions & Utils
import { isMobileV2, isTabletV2 } from 'src/utils/ui';
import { bannerClicked } from 'src/components/PromoBar/actions';
import useEventListener from 'src/hooks/useEventListener';
import { setPromotionalBannerCookie } from 'src/utils/cookies';

/**
 * If we run across a situation in which the promo bones out, lets offer a hook into console to see the issue
 * @type {debounced} throttle for one minute, 60 seconds.
 */
const throttleWarn = throttle((e) => {
  logger('_promo_banner_')(e);
}, 60000);

/**
 * Promo/Notification banner (GROW-132). A little involved due to the layout of the site and mobile vs. desktop.
 * User can dismiss for 2 days, see cookie code.
 *
 * @param promo
 * @returns {null|*}
 * @constructor
 */
const PromoBanner = (promoProps) => {
  if (!process.browser) return null;
  const { refHeader, searchIsOpen, sidebarIsOpen } = promoProps;

  try {
    const promoBannerContainer = useRef(null);
    const promoBannerHeight = useRef(48);
    const [promoDismissed, setPromoDismissed] = useState(null);
    const [parentHeader, setParentHeader] = useState(null);
    const [hideBannerOnMobile, setHideBannerOnMobile] = useState(false);

    // Split integration
    const { splitConfig } = useSplitIOTreatment(splitioConstants.SPLITIONAME_PROMO_BAR);
    const { textColor, backgroundColor } = splitConfig;

    const isMobileOrTablet = isMobileV2() || isTabletV2();

    const closePromoBanner = useCallback(() => {
      // set cookie to not show for a duration
      setPromoDismissed(setPromotionalBannerCookie());
    }, []);

    // Helper functions
    const setParentHeaderHeight = (value) => {
      if (parentHeader) {
        parentHeader.style.top = `-${
          Number.isInteger(value) ? value : promoBannerHeight.current
        }px`;
      }
    };

    const setHeightValues = () => {
      if (parentHeader && promoBannerContainer.current) {
        const { height } = promoBannerContainer.current.getBoundingClientRect();
        promoBannerHeight.current = height;
        setParentHeaderHeight(height);
      }
    };

    /**
      Let's save the parent ref (MainHeader) in a state. This will ensure us that the ref exists
      and that the ref for the promo also exists.
    */
    useEffect(() => {
      if (!parentHeader) {
        setParentHeader(refHeader.current);
      }
    }, [refHeader]);

    /**
      Once the parentHeader ref is saved, let's save the height of the promo banner
      and set the top position of the parent header. We're setting the top position
      because if the user scrolls, the offset will allow the parent header to stay
      flush to the top of the browser. Also, note that the parent header is positioned
      sticky.
    */
    useLayoutEffect(() => {
      setHeightValues();
    }, [parentHeader]);

    /**
      For Tablet/Mobile only - if the sidebar or search bar is open, hide the promo and
      set the parent header's top position to 0.
    */
    useLayoutEffect(() => {
      if (isMobileOrTablet) {
        if (sidebarIsOpen || searchIsOpen) {
          setHideBannerOnMobile(true);
          setParentHeaderHeight(0);
        } else {
          setHideBannerOnMobile(false);
          setParentHeaderHeight();
        }
      }
    }, [sidebarIsOpen, searchIsOpen]);

    /**
      Since the main header height changes at breakpoints, we need to update a few values.
    */
    const handleResize = useCallback(
      debounce(() => {
        setHeightValues();
      }, 300),
      [parentHeader],
    );

    useEventListener({ eventName: 'resize', handler: handleResize });

    /**
      When the promo is dismissed, set the parent header's top position to 0.
    */
    useLayoutEffect(() => {
      if (promoDismissed) {
        setParentHeaderHeight(0);
      }
    }, [promoDismissed]);

    /**
      Reset the parent header's top position when there is a route change. This ensures
      that the header is in the correct position if the new route doesn't display the promo.
    */
    useEffect(() => {
      return () => {
        if (parentHeader) {
          parentHeader.style.top = 0;
        }
      };
    }, [parentHeader]);

    if (promoDismissed || !parentHeader) return null;

    const promotionalContainerStyles = cn(styles.promotionalContainer, {
      [styles.hide]: hideBannerOnMobile,
    });

    return (
      <div
        ref={promoBannerContainer}
        className={promotionalContainerStyles}
        style={{
          color: textColor,
          backgroundColor,
        }}
      >
        <div className={styles.contentLeft}></div>
        <p className={`p1-hero ${styles.contentCenter}`}>
          <HeadlineLnk bannerClicked={bannerClicked} {...splitConfig} />
        </p>
        <div className={styles.contentRight}>
          <button
            onClick={closePromoBanner}
            type="button"
            className="plainButton"
            style={{ color: textColor }}
          >
            <Icon name="v2-close-icon" />
          </button>
        </div>
      </div>
    );
  } catch (e) {
    throttleWarn(e);
    return null;
  }
};

PromoBanner.propTypes = {
  refHeader: PropTypes.node,
  searchIsOpen: PropTypes.bool,
  sidebarIsOpen: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    sidebarIsOpen: state.getIn(['components', 'sidebar', 'open']),
    searchIsOpen: state.getIn(['components', 'search', 'show']),
  };
}

PromoBanner.displayName = 'PromoBar';

const PromoBar = connect(mapStateToProps, { bannerClicked })(PromoGuard(PromoBanner));
export default PromoBar;
