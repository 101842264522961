import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// cookies & utils
import { getPromotionalBannerCookie, getBrandingCookie } from 'src/utils/cookies';

// #####
// PATHS
// #####

/** These are the pages that the promo will not show on. */
const EXCLUDED_PATHS = [
  'cart',
  'account',
  'addSku',
  'landings',
  'location',
  'faq',
  'technician-help',
];
/**
 * Checks if any of the provided strings contain an excluded path.
 * @param {...string} routeStrings - Any number of strings to check against excluded paths
 * @returns {boolean}
 */
const isPathExcluded = (...routeStrings) =>
  EXCLUDED_PATHS.some((excludedPath) => routeStrings.some((rs) => rs.includes(excludedPath)));

// ########
// COOKIES
// ########

const COOKIE_EXCLUSION_FUNCTIONS = [getPromotionalBannerCookie, () => Boolean(getBrandingCookie())];

const isExcludedByCookies = () =>
  COOKIE_EXCLUSION_FUNCTIONS.some((cookieCheck) => cookieCheck() === true);

// #############
// FLOW CONTROL
// #############

const shouldHidePromoBar = (props) =>
  [
    () => isPathExcluded(props.pathName, props.currentPageName),
    isExcludedByCookies,
    () => !props.showSiteLinks,
  ].some((fn) => fn());

/**
 * This extracts out the determination of whether to show the promo or not as far as external
 * determinants. ie.. pagename. I asked if there would be more than one promo, and
 * they said no, so, no need to match a promo type with conditionals.
 *
 * We will use both pathname and pagename to determine to show.
 *
 * @param Component
 * @returns {Function}
 * @constructor
 *
 */
// eslint-disable-next-line react/display-name
const Guard = (Component) => (props) => {
  if (shouldHidePromoBar(props)) return null;

  return <Component {...props} />;
};

Guard.propTypes = {
  currentPageName: PropTypes.string,
  pathName: PropTypes.string,
  showSiteLinks: PropTypes.bool,
};

/**
 * Another item for easier transition when tool is ready. Assuming this is data path, if not,
 * just change it up to match.
 */
function mapStateToProps(state) {
  return {
    currentPageName: state.getIn(['pages', 'current', 'name']),
    pathName: state.getIn(['router', 'location', 'pathname']),
    showSiteLinks: state.getIn(['layout', 'header', 'showSiteLinks']),
  };
}

const PromoGuard = (Component) => connect(mapStateToProps)(Guard(Component));

PromoGuard.defaultProps = {
  pathName: '',
  currentPageName: '',
};

export default PromoGuard;
